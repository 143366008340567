import {Helmet, useTranslation} from "gatsby-plugin-react-i18next"
import {Channel, DownloadStepper, Steps} from "./components/DownloadStepper"
import {Button, Card, CardContent, Grid, TextField} from "@material-ui/core"
import {Layout} from "../../components/Layout"
import {ResponsiveContainer} from "../../components/Shared"
import {DownloadBoardsYaml, DownloadCategoriesYaml, RecalboxChannel} from "../../../graphql-types"
import * as React from "react"
import {ChangeEvent, FormEvent, useState} from "react"
import {TextCenteredGrid} from "../diy/components/Shared"
import {useDispatch} from "react-redux"
import {MdTypo, TransMdTypo} from "../../components/i18n/TransMdTypo"
import {DatabaseSystems} from "../database/SystemsPage"
import {Blog} from "../blog/BlogPaginedPage"
import downloadOGImage from "../../data/images/opengraph/card-download.png"
import {useI18nLink} from "../../components/i18n/I18nLink"

export const Play = (props: { pageContext: { alternative: boolean, board: DownloadBoardsYaml, channel: RecalboxChannel, category: DownloadCategoriesYaml } }) => {
  const {alternative, board, category} = props.pageContext
  const channel = props.pageContext.channel.name as Channel
  const [email, setEmail] = useState("")
  const dispatch = useDispatch()
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }
  const {t, i18n} = useTranslation()
  const i18NLink = useI18nLink()
  return (
    <Layout title={`Recalbox Download - ${t("download^Next!")}`}
            description={t("download^Download and install Recalbox for your Rasbperry Pi, PC, Handheld and Odroid!")}
            imageUrl={downloadOGImage}>
      <Helmet>
        <script src="/mautic.js" type="text/javascript"/>
      </Helmet>
      <DownloadStepper step={Steps.Play} board={board}
                       channel={channel}
                       category={category}/>
      <ResponsiveContainer>
        <Card>
          <CardContent>
            <TransMdTypo gutterBottom variant={"h3"}>
              download^Thank you!
            </TransMdTypo>
            <TransMdTypo gutterBottom>
              download^And then ? Follow us
            </TransMdTypo>
            <MdTypo children={t("download^Systems and blog", {
              systemslink: i18NLink.getLink(DatabaseSystems.getUrl()),
              bloglink: i18NLink.getLink(Blog.getUrl(1))
            })}/>
            <form onSubmit={handleSubmit}
                  autoComplete="false" role="form"
                  method="post"
                  action="https://market.recalbox.com/form/submit?formId=1" id="mauticform_newletterfromdownloadpage"
                  data-mautic-form="newletterfromdownloadpage" encType="multipart/form-data">

              <input type="hidden" name="mauticform[formId]" id="mauticform_newletterfromdownloadpage_id" value="1"/>
              <input type="hidden" name="mauticform[return]" id="mauticform_newletterfromdownloadpage_return" value=""/>
              <input type="hidden" name="mauticform[formName]" id="mauticform_newletterfromdownloadpage_name"
                     value="newletterfromdownloadpage"/>
              <input id="mauticform_input_newletterfromdownloadpage_board" name="mauticform[board]"
                     value={board.id} type="hidden"/>
              <input id="mauticform_input_newletterfromdownloadpage_downloadlang"
                     name="mauticform[downloadlang]" value={i18n.language} type="hidden"/>
              <TextCenteredGrid container spacing={1} css={{marginTop: "3em"}}>
                <Grid item xs={12}>
                  <TextField variant="outlined" placeholder="iamretrogamer@recalbox.com" type="email"
                             name="mauticform[email]" label="Email" value={email} onChange={handleChange}
                             id="email-address"/>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="outlined" type="submit" name="mauticform[submit]"
                          id="mauticform_input_newletterfromdownloadpage_submit"
                  >{t("download^Subscribe to the newsletter")}</Button>
                </Grid>
                <Grid item xs={12}>
                  <div className="mauticform-message"
                       id="mauticform_newletterfromdownloadpage_message"></div>
                </Grid>
              </TextCenteredGrid>
            </form>
          </CardContent>
        </Card>
      </ResponsiveContainer>
    </Layout>)
}


export const PlayPage = {
  Page: Play,
  getUrl: (channel: Channel, category: string, board: string) => `/download/${channel}/${category}/${board}/play/`
}

export default PlayPage.Page
