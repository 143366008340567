import {Layout} from "../../components/Layout"
import {ResponsiveContainer} from "../../components/Shared"
import {Box, Fab, Grid} from "@material-ui/core"
import {PostCard} from "./components/PostCard"
import {graphql, useStaticQuery} from "gatsby"
import {BlogPost as BlogPostQuery, Query} from "../../../graphql-types"
import {toBlogPost} from "./graphql"
import {RootState} from "../../store/store"
import {connect, ConnectedProps, useDispatch} from "react-redux"
import {RiAddFill} from "@react-icons/all-files/ri/RiAddFill"
import {Link} from "../../components/i18n/I18nLink"
import {BlogPost, BlogPostWithParse} from "../../api/ParseObjects"
import {RiListSettingsFill} from "@react-icons/all-files/ri/RiListSettingsFill"
import React, {useEffect, useState} from "react"
import * as blogApi from "../../api/blogApi"
import {PostEdit} from "./PostEdit"
import {useI18next} from "gatsby-plugin-react-i18next"
import {PrivatePage} from "../../components/PrivateRoutePage"
import {blogVisit} from "../profile/profileSlice"
import {WriterBlog} from "./WriterBlogPage"
import {TextCenter} from "../diy/components/Shared"

const sortPosts = (a: BlogPost, b: BlogPost) => b.publishDate.getTime() - a.publishDate.getTime()

const getCurrentLanguageOrDefaultPosts = (posts: BlogPost[], language: string) => {
  const currentLanguagePosts = posts.filter(p => p.language === language)
  const missingEnglishPosts = posts.filter(p => p.language === "en").filter(p => !currentLanguagePosts.find(cp => cp.i18nId === p.i18nId))
  return currentLanguagePosts.concat(missingEnglishPosts).sort(sortPosts)
}

const GatsbyBlogPaginedPage = (props: BlogPaginedPageProps) => {
  const dispatch = useDispatch()
  const allPublishedPosts = props.pageContext.posts
    .map((post) => toBlogPost(post))
    .filter(post => post.state === "published")
  const {language} = useI18next()

  useEffect(()=>{
    dispatch(blogVisit({lastVisit: new Date().getTime(), toRead: 0}))
  },[])

  const button = props.isLogged && props.user?.roles?.find(_ => _ === "writer") ?
    <Box textAlign={"right"}>
      <Fab title={"Go to writer's blog page"} color="primary" aria-label="list" component={Link}
           to={WriterBlog.getUrl()}>
        <RiListSettingsFill/>
      </Fab>
    </Box>
    : undefined
   return <BlogPage title={"Blog"} button={button}
                    posts={getCurrentLanguageOrDefaultPosts(allPublishedPosts, language)}
                    page={props.pageContext.page} totalPages={props.pageContext.totalPages}/>
}

const BlogPage = (props: { title: string, page: number, totalPages: number, posts: BlogPost[], button?: React.ReactElement, plusInfo?: boolean }) => {
  const {posts, button} = props
  console.log(props)
  return (
    <Layout title={props.title}>
      <ResponsiveContainer>
        {button &&
        <Box textAlign={"right"}>
          {button}
        </Box>
        }
        <Grid container spacing={3}>
          {posts.map(post => (
            <Grid item xs={12} lg={posts.length > 1 ? 6 : 12}>
              <PostCard post={post} plusInfo={props.plusInfo}/>
            </Grid>
          ))}
        </Grid>
        <Box marginTop={3}>
          {props.totalPages && props.totalPages > 1 &&
          <TextCenter>
            {props.page > 1 && <Link to={Blog.getUrl(props.page-1)}>{"< "}</Link> }
            {[...Array(props.totalPages).keys()].map(p => <Link to={Blog.getUrl(p+1)}>{
              p + 1 === props.page ? <span css={{textDecoration: "underline"}}>{p + 1} </span> : <span>{p + 1} </span>}</Link>)}
            {props.page < props.totalPages && <Link to={Blog.getUrl(props.page+1)}>{">"}</Link> }
          </TextCenter>
          }
        </Box>
      </ResponsiveContainer>
    </Layout>
  )
}

const mapState = (state: RootState) => {
  return {
    isLogged: state.profile.isLogged,
    user: state.profile.user,
    blogInfo: state.profile.blogInfo,
  }
}
const connector = connect(mapState)
type BlogPaginedPageProps = ConnectedProps<typeof connector> & { pageContext: {page: number, totalPages: number, posts: BlogPostQuery[]} };
const GatsbyBlogPaginedPageRedux = connector(GatsbyBlogPaginedPage)

export const Blog = {
  Page: GatsbyBlogPaginedPageRedux,
  getUrl: (page: number) => `/blog/page/${page}`,
}

export default Blog.Page