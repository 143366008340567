import {Layout} from "../../components/Layout"
import {ResponsiveContainer} from "../../components/Shared"
import {PostCard} from "./components/PostCard"
import {graphql} from "gatsby"
import {toBlogPost} from "./graphql"
import {useI18next} from "gatsby-plugin-react-i18next"
import {RootState} from "../../store/store"
import {connect, ConnectedProps} from "react-redux"
import {Box, Fab} from "@material-ui/core"
import {RiEdit2Fill} from "@react-icons/all-files/ri/RiEdit2Fill"
import {Link} from "../../components/i18n/I18nLink"
import {PostEdit} from "./PostEdit"
import {BlogPost, Query} from "../../../graphql-types"
import { FastCommentsCommentWidget } from "fastcomments-react"

const PostPage = (props: PostPageProps) => {
  const {language} = useI18next()
  const posts = props.pageContext.posts.map((post) => toBlogPost(post))
  const currentLangPost = posts.find(post => post.language === language) ?? posts.find(post => post.language === "en") ?? posts[0]
  return (
    <Layout title={currentLangPost.title}
            description={currentLangPost.content.substr(0, 300).replace(/#/g, "").replace(/\*/g, "")}
            imageUrl={currentLangPost.localJumbo!.childImageSharp!.original!.src!}>
      <ResponsiveContainer>
        {props.isLogged && props.user?.roles?.find(r => r === "writer") &&
        <Box textAlign={"right"}>
          <Fab color="primary" aria-label="edit" component={Link} to={PostEdit.getUrl(currentLangPost.i18nId)}>
            <RiEdit2Fill/>
          </Fab>
        </Box>
        }
        <PostCard post={currentLangPost} full/>
        <FastCommentsCommentWidget tenantId="eu-wKwOixbq31R" region="eu" />
      </ResponsiveContainer>
    </Layout>
  )
}


const mapState = (state: RootState) => {
  return {
    isLogged: state.profile.isLogged,
    user: state.profile.user
  }
}
const connector = connect(mapState)
type PostPageProps = ConnectedProps<typeof connector> & { pageContext: {posts: BlogPost[] } };

export const Post =
    {
      Page: connector(PostPage),
      getUrl: (postId: string, review: boolean = false) => `/blog/${review ? "review/": ""}${postId}`
    }

export default Post.Page
