import {Layout} from "../../components/Layout"
import {ResponsiveContainer} from "../../components/Shared"
import {Box, Fab, Grid} from "@material-ui/core"
import {PostCard} from "./components/PostCard"
import {graphql, useStaticQuery} from "gatsby"
import {Query} from "../../../graphql-types"
import {toBlogPost} from "./graphql"
import {RootState} from "../../store/store"
import {connect, ConnectedProps, useDispatch} from "react-redux"
import {RiAddFill} from "@react-icons/all-files/ri/RiAddFill"
import {Link} from "../../components/i18n/I18nLink"
import {BlogPost} from "../../api/ParseObjects"
import {RiListSettingsFill} from "@react-icons/all-files/ri/RiListSettingsFill"
import React, {useEffect, useState} from "react"
import * as blogApi from "../../api/blogApi"
import {PostEdit} from "./PostEdit"
import {useI18next} from "gatsby-plugin-react-i18next"
import {PrivatePage} from "../../components/PrivateRoutePage"
import {blogVisit} from "../profile/profileSlice"

const sortPosts = (a: BlogPost, b: BlogPost) => b.publishDate.getTime() - a.publishDate.getTime()

const getCurrentLanguageOrDefaultPosts = (posts: BlogPost[], language: string) => {
  const currentLanguagePosts = posts.filter(p => p.language === language)
  const missingEnglishPosts = posts.filter(p => p.language === "en").filter(p => !currentLanguagePosts.find(cp => cp.i18nId === p.i18nId))
  return currentLanguagePosts.concat(missingEnglishPosts).sort(sortPosts)
}

const WriterBlogPage = (props: BlogPageProps) => {
  const [posts, setPosts] = useState<BlogPost[]>([])
  useEffect(() => {
    blogApi.findAllDrafts().then((posts) => {
      setPosts(posts.map(p => p.attributes).sort(sortPosts))
    })
  }, [])

  const button = <Box textAlign={"right"}>
    <Fab title={"Add a blog post"} color="primary" aria-label="add" component={Link} to={PostEdit.getUrl()}>
      <RiAddFill/>
    </Fab>
  </Box>
  return <BlogPage title={"Writer's Blog Page"} button={button} posts={posts} plusInfo={props.isLogged}/>
}

const BlogPage = (props: { title: string, posts: BlogPost[], button?: React.ReactElement, plusInfo?: boolean }) => {
  const {posts, button} = props
  return (
      <Layout title={props.title}>
        <ResponsiveContainer>
          {button &&
              <Box textAlign={"right"}>
                {button}
              </Box>
          }
          <Grid container spacing={3}>
            {posts.map(post => (
                <Grid item xs={12} lg={posts.length > 1 ? 6 : 12}>
                  <PostCard post={post} plusInfo={props.plusInfo}/>
                </Grid>
            ))}
          </Grid>
        </ResponsiveContainer>
      </Layout>
  )
}

const mapState = (state: RootState) => {
  return {
    isLogged: state.profile.isLogged,
    user: state.profile.user,
    blogInfo: state.profile.blogInfo,
  }
}
type BlogPageProps = ConnectedProps<typeof connector>;
const connector = connect(mapState)
const ParseBlogPageRedux = connector(WriterBlogPage)

export const WriterBlog: PrivatePage = {
  Page: ParseBlogPageRedux,
  getUrl: () => "/plus/blog/list",
  minimumRole: "writer"
}
