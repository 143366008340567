import {SectionContainer, SectionTitle} from "../../components/Shared"
import {Layout} from "../../components/Layout"
import React, {useRef, useState} from "react"
import {CardContent, CardMedia, Grid, Typography} from "@material-ui/core"
import {graphql, useStaticQuery} from "gatsby"
import {GatsbyImage} from "gatsby-plugin-image"
import {FocusableCard} from "../myrecalbox/components/FocusableCard"
import {CSSTransition} from "react-transition-group"
import styled from "@emotion/styled"
import {IoMdCalendar} from "@react-icons/all-files/io/IoMdCalendar"
import {Page} from "../../components/PrivateRoutePage"
import {useTranslation} from "gatsby-plugin-react-i18next"
import {TransMdTypo} from "../../components/i18n/TransMdTypo"
import {Query, RecalboxSystem} from "../../../graphql-types"


const SystemCardMedia = styled(CardMedia)(({theme}) => ({
  position: "relative", background: theme.palette.background.surface1, height: "180px",
}))

const SystemVideo = styled.video({
  objectFit: "cover", width: "100%", height: "100%"
})

const SystemIllustration = styled.div({
  position: "absolute", top: "50%", width: "100%", transform: "translateY(-50%)", textAlign: "center"
})

const SystemCard = (props: { system: RecalboxSystem }) => {

  const {system} = props
  const {t} = useTranslation()
  const videoRef = useRef<HTMLVideoElement>(null)
  const [focused, setFocused] = useState(false)
  const startVideo = () => {
    if (videoRef?.current) {
      videoRef.current.play()
      setFocused(true)
    }
  }
  const stopVideo = () => {
    if (videoRef?.current) {
      videoRef.current.pause()
      setFocused(false)
    }
  }

  return (
    <FocusableCard css={{cursor: "pointer"}} onMouseOverCapture={() => startVideo()}
                   onMouseLeave={() => stopVideo()}
                   onFocus={() => startVideo()}
                   onBlur={() => stopVideo()}>
      {/*      <CardActionArea
                      component={Link} to={`/database/games/?system=${system.systemId}`}
      >*/}
      <SystemCardMedia>
        {system.videoLocal?.publicURL &&
        <SystemVideo preload="none" ref={videoRef} muted css={focused ? {} : {filter: "blur(5px)"}}>
          <source src={`${system.videoLocal?.publicURL}`} type="video/mp4"/>
        </SystemVideo>
        }
        <SystemIllustration>
          <CSSTransition in={!focused} classNames={"translateY-top"} addEndListener={() => {
          }}>
            <GatsbyImage image={system.wheelLocal?.childImageSharp?.gatsbyImageData}
                         alt={system.noms?.nom_eu ?? "???"}/>
          </CSSTransition>
        </SystemIllustration>
      </SystemCardMedia>
      <CardContent>
        <Typography variant={"h6"}>
          {system.noms?.nom_eu ?? "???"}
        </Typography>
        <Grid container direction="row" alignItems="center">
          {system.datedebut != null ?
            <React.Fragment>
              <IoMdCalendar size={20}/><TransMdTypo
              children={t("database^XX years ago", {date: new Date().getFullYear() - new Date(system.datedebut).getFullYear()})}/>
            </React.Fragment>
            :
            <React.Fragment>
              <IoMdCalendar size={20}/><TransMdTypo>database^A long time ago</TransMdTypo>
            </React.Fragment>
          }
        </Grid>

      </CardContent>
      {/*
      </CardActionArea>
*/}
    </FocusableCard>
  )
}
export const SystemsPage = () => {
  const {t} = useTranslation()
  const recalboxSystems = useStaticQuery<Query>(graphql`
  query RecalboxSystemDatabase {
    allRecalboxSystem {
      edges {
        node {
          systemId
          noms {
            nom_eu
            noms_commun
          }
          datedebut
          wheelLocal {
            childImageSharp {
              gatsbyImageData(
               height: 120,
               placeholder: BLURRED
             )
            }
          }
          videoLocal {
            publicURL
          }
        }
      }
    }
  }
  `).allRecalboxSystem.edges.map(_ => _.node)

  return (
    <Layout title={t("database^Supported systems")}>
      <SectionContainer>
        <SectionTitle component={"h1"} css={{marginBottom: "1em"}}>{t("database^All supported systems")}</SectionTitle>
        <Grid container spacing={3}>
          {recalboxSystems.map((system) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <SystemCard key={system.systemId} system={system}></SystemCard>
              </Grid>
            )
          })}

        </Grid>
      </SectionContainer>
    </Layout>
  )
}

export const DatabaseSystems: Page = {Page: SystemsPage, getUrl: () => "/database"}
